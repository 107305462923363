
















































































import { Component, Vue, Prop } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import QuestionnaireCopyDialog from "@/components/dialogs/CopyQuestionnaireDialog.vue";
import ImportQuestionnaireDialog from "@/components/dialogs/ImportQuestionnaireDialog.vue";
import { Location } from "vue-router";
import DataProvider from "@/lib/DataProvider";
import { Questionnaire } from "@/data/models/Questionnaires";
import ICrudClient from "@/lib/ICrudClient";
import { saveAs } from "file-saver";

@Component({
  components: {
    ModelTable,
    QuestionnaireCopyDialog,
    ImportQuestionnaireDialog,
  },
})
export default class SettingsTable extends Vue {
  @Prop()
  public provider!: DataProvider<Questionnaire>;

  @Prop()
  public crud!: ICrudClient<Questionnaire>;

  @Prop()
  public columns!: any[];

  @Prop()
  public addRoute!: (id: string) => Location;

  @Prop()
  public viewRoute!: (id: string) => Location;

  private selected: any[] = [];

  private prescreening = false;
  private prescreeningDialog = false;

  private async setAsPrescreening() {
    this.prescreening = true;
    const payload = JSON.parse(JSON.stringify(this.selected[0]));
    payload.isActive = true;
    await this.crud.saveAsync(payload);
    this.prescreening = false;
    this.prescreeningDialog = false;
    (this.$refs.modelTable as any).refresh();
  }

  private navigateToRoute(route: (id: string) => Location, id: any) {
    if (!route) {
      return;
    }
    this.$router.push(route(id));
  }

  private download(item: any) {
    saveAs(
      new Blob([JSON.stringify(item)], { type: "application/text" }),
      item.name + ".txt"
    );
  }
}
